import BigNumber from 'bignumber.js'
import React, { Fragment, useEffect, useState } from 'react'
import { useMatchBreakpoints } from '@pancakeswap/uikit'
import ConnectWalletButton from 'components/ConnectWalletButton';

const Header: React.FC = () => {

    const { isMobile } = useMatchBreakpoints()

    return (
        <header id="header container" >
            {/* Navbar */}
            <nav data-aos="zoom-out" data-aos-delay={800} className="headernav">
                <div className="headercomponents">
                    <a href='https://mint.proudhouse.io/' style={{height:"70px"}}>              
                        <img className={!isMobile ? "headerlogo" : "headerlogomobile"} style={{height:"100%"}} src="img/logo.png"  alt="sticky brand-logo" />
                    </a>
                    <span>
                        {/* <ConnectWalletButton headstring="Wallet ID:" colorHeading="#ffc000" colorAddress="#ffffff" colorIcon="primary" /> */}
                        <ConnectWalletButton />
                    </span>
                </div>
            </nav>
        </header>
    );
}

export default Header
